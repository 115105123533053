/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import {
  dealCreationAddSave,
  dealServicerViewDetails,
  dealCreationCreateUpdate,
  dealCreationDelete,
  dealCreationSaveForNow,
  dealCreationBCStatus,
  dealCreationAutoSave,
  dealCreationEdit,
} from "../../../../../servies/services";
import { connect } from "react-redux";
import Header from "../../../../../components/header";
import moment from "moment";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import ReactModal from "react-modal";
import {
  customStylesServicer,
  customStylesauto,
} from "../../../../../components/customscripts/customscript";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import NumberComp2 from "../../../../../components/NumberComp2";
import Switch from "@material-ui/core/Switch";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
// import * as moment from "moment";

class ViewDetails_Deal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      rowsSelected: null,
      detailsDateViewDetails:
        this.props.location?.state?.details === undefined
          ? this.props.location?.state?.ViewDate
          : this.props.location?.state?.details,
      isloantapestatus: false,
      screenloader: false,
      rowView: true,
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      isRecurring: this.props.location?.state?.checkRecurring,
      peer: sessionStorage.getItem("peer"),
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  handleChange1 = (e) => {
    // togglevat
    if (this.state.addswitchtoggle === false) {
      this.setState({ addswitchtoggle: true });
    } else {
      this.setState({ addswitchtoggle: false });
    }
  };
  handleChange2 = (e) => {
    // togglevat
    if (this.state.editswitchtoggle === false) {
      this.setState({ editswitchtoggle: true });
    } else {
      this.setState({ editswitchtoggle: false });
    }
  };

  dealServicerViewDetails = async () => {
    let selectdate =
      this.state.detailsDateViewDetails === undefined
        ? sessionStorage.getItem("selectdate")
        : this.state.detailsDateViewDetails;
    let x = moment(selectdate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = this.state?.isRecurring
      ? this.props.location?.state?.Month
      : month;
    data.Year = this.state?.isRecurring
      ? this.props.location?.state?.Year
      : year;
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ screenloader: true });
    const APIResponse = await dealServicerViewDetails(data);

    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        loading: false,
        screenloader: false,
      });
      let x = [];
      APIResponse.data.map((item, index) => {
        x.push(item.ServicerName);
      });
      sessionStorage.setItem(
        "ClosingDate",
        APIResponse.data.length > 0 ? APIResponse.data[0]["ClosingDate"] : ""
      );

      this.setState({ ServicerList: x });

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  AllSummary = () => {
    this.props.history.push({
      pathname: "/admin/summarize",
      state: { TopWholeSummary: "Yes", ServicerList: this.state.ServicerList },
    });
  };
  ProcessClosingTape = () => {
    sessionStorage.setItem("isClosingTape",true);
    this.props.history.push({
      pathname: "/admin/closing_uploadloantape",
    });
  };
  Summary = (tableMeta) => {
    this.setState({ rowView: true });
    sessionStorage.setItem("Servicer", tableMeta.rowData[1]);
    this.props.history.push({
      pathname: "/admin/summarize",
      state: {
        RowIndividualSummary: "Yes",
        rowViewDetails: this.state.rowView,
        detailsDate:
          this.state.detailsDateViewDetails === undefined
            ? sessionStorage.getItem("selectdate")
            : this.state.detailsDateViewDetails,
        Servicer: sessionStorage.getItem("Servicer"),
      },
    });
  };
  UploadLoanTape = (value, tableMeta) => {
    console.log("tableMeta", this.state.detailsDateViewDetails);
    sessionStorage.setItem("isClosingTape", false);
    this.props.history.push({
      pathname: "/admin/uploadloantape",
      state: {
        detailsView:
          this.state.detailsDateViewDetails === undefined
            ? sessionStorage.getItem("selectdate")
            : this.state.detailsDateViewDetails,
        dealname: value,
        servicer: tableMeta.rowData[1],
      },
    });
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("selectdate", this.state.detailsDateViewDetails);
    this.dealServicerViewDetails();
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
      },
    });

  goBackToDashBoard = () => {
    this.props.location?.state?.checkRecurringGeneral
      ? this.props.history.push({ pathname: "/admin/general_recurring" })
      : this.props.location?.state?.checkRecurringTranches
      ? this.props.history.push({ pathname: "/admin/tranches_recurring" })
      : this.props.location?.state?.checkRecurringFees
      ? this.props.history.push({ pathname: "/admin/fees_recurring" })
      : this.props.location?.state?.checkRecurringExpenses
      ? this.props.history.push({ pathname: "/admin/expenses_recurring" })
      : this.props.location?.state?.checkRecurringAccounts
      ? this.props.history.push({ pathname: "/admin/accounts_recurring" })
      : this.props.location?.state?.checkRecurringTests
      ? this.props.history.push({ pathname: "/admin/tests_recurring" })
      : this.props.location?.state?.checkRecurringVariables
      ? this.props.history.push({ pathname: "/admin/variable_recurring" })
      : this.props.location?.state?.checkRecurringBorrowingBase
      ? this.props.history.push({ pathname: "/admin/borrowingbase_recurring" })
      : this.props.location?.state?.checkRecurringPaymentRules
      ? this.props.history.push({ pathname: "/admin/payment_recurring" })
      : this.props.location?.state?.checkRecurringCollateral
      ? this.props.history.push({ pathname: "/admin/collateral_recurring" })
      : this.props.location?.state?.checkRecurringLoanTape
      ? this.props.history.push({ pathname: "/admin/loantape_recurring" })
      : this.props.location?.state?.checkRecurringConsolidated
      ? this.props.history.push({ pathname: "/admin/consolidated_recurring" })
      : this.props.history.push({
          pathname: "/dashboard",
        });
  };

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        name: "DealName",
        label: "Deal Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "ServicerName",
        label: "Servicer",
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                textAlign: "center",
                paddingTop: "5px",
                paddingLeft: "2rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "2rem",
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },

      {
        name: "LoanTapeLoadStatus",
        label: "Loan Processed",
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                textAlign: "center",
                paddingTop: "5px",
                paddingLeft: "2rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "2rem",
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },
      {
        name: "LastModified",
        label: "Last Modified",
        options: {
          filter: true,
          sort: true,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                textAlign: "center",
                paddingTop: "5px",
                paddingLeft: "2rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "2rem",
              }}
            >
              <tr>{value}</tr>
            </div>
          ),
        },
      },
      {
        name: "DealName",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "10rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div
                  className="align-viewDetails"
                  style={{ justifyContent: "center", paddingLeft: "4rem" }}
                >
                  {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
                  {tableMeta.rowData[2] === "Yes" ? (
                    <button
                      className="popupbutton1"
                      onClick={() => this.Summary(tableMeta)}
                      style={{ marginRight: "1.2rem" }}
                    >
                      View Aggregate Summary
                    </button>
                  ) : (
                    <button
                      className="popupbuttonViewdetails"
                      style={{ marginRight: "1.2rem" }}
                      disabled
                    >
                      View Aggregate Summary
                    </button>
                  )}

                  <button
                    onClick={() => this.UploadLoanTape(value, tableMeta)}
                    className="popupbutton1"
                  >
                    Process Loan Tape
                  </button>
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
    ];

    const checkStatus = () => {
      console.log("tableData", this.state.tableData);
      return this.state.tableData.every(
        (item) => item.LoanTapeLoadStatus === "Yes"
      );
    };

    const status = checkStatus();

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Dashboard"} />
          <div className="content">
            <div className="header">
              <Header></Header>
            </div>

            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div className="row1">
                    <div className="investor-heading-container">
                      <div className="col-12 col-sm-6 col-md-8 d-flex justigy-content-center align-center hellocard">
                        <KeyboardBackspaceIcon
                          onClick={() => this.goBackToDashBoard()}
                          className="left-arrow-muis1 left-arrow-servicer"
                        ></KeyboardBackspaceIcon>
                        <h1 className="headerdashboard">Loan Details</h1>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-5 d-flex align-items-center hellocard">
                    <div className="buttonsverified uw-deal-details-button-container d-flex ml-auto">
                      {status ? (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => this.AllSummary()}
                            className="popupbutton1"
                          >
                            View Aggregate Summary
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.ProcessClosingTape()}
                          >
                            Process Closing Tape
                          </Button>
                        </>
                      ) : (
                        <div>
                          <Button
                            variant="outlined"
                            className="popupbuttonViewdetails custom-disabled-button"
                            disabled
                          >
                            View Aggregate Summary
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.ProcessClosingTape()}
                          >
                            Process Closing Tape
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-sm-6 col-md-7 d-flex justigy-content-center align-center details movement-header-viewdetails-paymentdate"
                  // style={{ position: "relative", top: "1rem" }}
                >
                  <h5 className="paymentdate">Payment Date : </h5>
                  <div
                    className="input-generalContainer"
                    style={{
                      marginTop: "3rem",
                      position: "relative",
                      right: "4rem",
                    }}
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        margin="normal"
                        id="date1"
                        value={
                          this.state.detailsDateViewDetails === undefined
                            ? sessionStorage.getItem("selectdate")
                            : this.state.detailsDateViewDetails
                        }
                        onChange={(date) =>
                          this.setState(
                            {
                              detailsDateViewDetails: moment(date)
                                .format("MM/DD/YYYY")
                                .toString(),
                            },
                            () =>
                              console.log(
                                "date",
                                this.state.detailsDateViewDetails
                              )
                          )
                        }
                        keyboard
                        placeholder="MM/DD/YYYY"
                        format={"MM/DD/YYYY"}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        autoOk={true}
                        clearable
                        variant="filled"
                        helperText={""}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {/* <div style={{"position":"relative",top:"8rem","height":"20rem"}}>          
                  <DatePicker
                    id="date"
                    value={new Date(this.state.detailsDateViewDetails)}
                    onChange={date=>this.setState({detailsDateViewDetails:date})}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    clearIcon={false}
                    className="calendar"
                    />
                    </div>  */}
                </div>

                <React.Fragment>
                  <div className="workbench-table-container movement-table-viewdetails">
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <MUIDataTable
                        // title={'Dashboard'}
                        data={this.state.tableData}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </div>
                </React.Fragment>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(ViewDetails_Deal);
